<template>
  <div class="main-layout">

    <!--====== PRELOADER PART START ======-->
    <Preloader />
    <!--====== PRELOADER ENDS START ======-->
    <Modal
      ref="refsModal"
      :title="modal.title"
      :message="modal.message"
    />

    <header
      id="home"
      class="header-area pt-100"
    >
      <div class="shape header-shape-one">
        <img
          src="../assets/images/banner/shape/shape-1.png"
          alt="shape"
        />
      </div>
      <!-- header shape one -->
      <div class="shape header-shape-tow animation-one">
        <img
          src="../assets/images/banner/shape/shape-2.png"
          alt="shape"
        >
      </div>
      <!-- header shape tow -->
      <div class="shape header-shape-three animation-one">
        <img
          src="../assets/images/banner/shape/shape-3.png"
          alt="shape"
        />
      </div>
      <!-- header shape three -->
      <div class="shape header-shape-fore">
        <img
          src="../assets/images/banner/shape/shape-4.png"
          alt="shape"
        />
      </div>
      <!-- header shape three -->
      <div class="header-banner d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-9 col-sm-10">
              <div class="banner-content">
                <h4
                  class="sub-title wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="1s"
                >
                  Your trusted
                </h4>
                <h1
                  class="banner-title mt-10 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="2s"
                >
                  <span>Interior</span> Design Partner for Home or Office
                </h1>
                <a
                  class="banner-contact mt-25 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="2.3s"
                  href="#contact"
                >Get a Free Quote</a>
              </div>
              <!-- banner content -->
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- container -->
        <div class="fadeIn banner-image bg_cover bn">
        </div>
      </div>
      <!-- header banner -->
    </header>

    <router-view></router-view>

    <footer
      id="footer"
      class="footer-area"
    >
      <div class="footer-widget pt-80 pb-130">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-8">
              <div class="footer-logo mt-50">
                <a href="#">
                  <img
                    src="../assets/images/logo.png"
                    alt="Logo"
                  />
                </a>
                <ul class="footer-info">
                  <li>
                    <div class="single-info">
                      <div class="info-icon">
                        <i class="lni-phone-handset"></i>
                      </div>
                      <div class="info-content">
                        <p>+38 098 555 55 55</p>
                      </div>
                    </div>
                    <!-- single info -->
                  </li>
                  <li>
                    <div class="single-info">
                      <div class="info-icon">
                        <i class="lni-envelope"></i>
                      </div>
                      <div class="info-content">
                        <p>contact@yourmail.com</p>
                      </div>
                    </div>
                    <!-- single info -->
                  </li>
                  <li>
                    <div class="single-info">
                      <a
                        id="address"
                        href="javascript:void(0)"
                        @click="getPlace()"
                        data-bs-toggle="tooltip"
                        title="Open google map"
                      >
                        <div class="info-icon">
                          <i class="lni-map"></i>
                        </div>
                        <div class="info-content">
                          <p>39600 Soborna 16/9 Kremenchuk, UA</p>
                        </div>
                      </a>
                    </div>
                    <!-- single info -->
                  </li>
                </ul>
                <ul class="footer-social mt-20">
                  <li>
                    <a href="#"><i class="lni-facebook-filled"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="lni-twitter-original"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="lni-google"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="lni-instagram"></i></a>
                  </li>
                </ul>
              </div>
              <!-- footer logo -->
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="footer-link mt-45">
                <div class="f-title">
                  <h4 class="title">Essential Links</h4>
                </div>
                <ul class="mt-15">
                  <li><a href="#about">About</a></li>
                  <li><a href="#project">Projects</a></li>
                  <li>
                    <router-link to="/support">Support</router-link>
                  </li>
                  <li>
                    <router-link to="/address">Address</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="footer-link mt-45">
                <div class="f-title">
                  <h4 class="title">Services</h4>
                </div>
                <ul class="mt-15">
                  <li>
                    <router-link to="/products">Product Design</router-link>
                  </li>
                  <li><a href="#">Research</a></li>
                  <li><a href="#">Office Management</a></li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-5 col-sm-8">
              <div class="footer-newsleter mt-45">
                <div class="f-title">
                  <h4 class="title">Newsleter</h4>
                </div>
                <p class="mt-15">
                  Lorem ipsum dolor sit amet, consec tetur adipiscing elit.
                </p>
                <form action="">
                  <div class="newsleter mt-20">
                    <input
                      type="email"
                      v-if="!v$.form.mail.$error"
                      class="text-success"
                      placeholder="info@contact.com"
                      name="mail"
                      v-model.trim="form.mail"
                      @blure="v$.form.mail.$touch()"
                      @focus="v$.form.mail.$touch()"
                      @keyup="v$.form.mail.$touch()"
                    />
                    <input
                      type="email"
                      v-else
                      class="text-danger"
                      placeholder="info@contact.com"
                      name="mail"
                      v-model.trim="form.mail"
                      @blure="v$.form.mail.$touch()"
                      @focus="v$.form.mail.$touch()"
                      @keyup="v$.form.mail.$touch()"
                    />
                    <transition name="fadeIn">
                      <div
                        class="invalid-feedback"
                        v-if="v$.form.mail.$error"
                        style="display: block"
                      >
                        <div
                          v-for="(error, index) of v$.form.mail.$errors"
                          :key="index"
                        >
                          {{ error.$message }}
                        </div>
                      </div>
                    </transition>
                    <button
                      type="button"
                      @click="send()"
                    >
                      <i class="lni-angle-double-right"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- container -->
      </div>
      <!-- footer widget -->
      <div class="copyright-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="copyright text-center">
                <p>Copyright</p>
              </div>
              <!-- copyright -->
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- container -->
      </div>
      <!-- copyright-area -->
    </footer>

    <!--====== FOOTER PART ENDS ======-->

    <!--====== BACK TO TOP PART START ======-->

    <a
      href="#"
      class="back-to-top"
    ><i class="lni-chevron-up"></i></a>

    <!--====== BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import "../assets/css/animate.css"
import axios from "axios";
import Modal from "@/components/Modal.vue";
import useVuelidate from "@vuelidate/core";

import { helpers, required } from "@vuelidate/validators";
import $ from "../assets/js/vendor/jquery-1.12.4.min.js";
import WOW from "../assets/js/wow.min.js";
import Preloader from "../components/Preloader";

const emails = helpers.regex(
  /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-z]{2,3})$/
);

const querystring = require("querystring");

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    Preloader,
    Modal,
  },
  data() {
    return {
      modal: {
        title: "",
        message: "",
      },
      form: {
        mail: "",
      },
    };
  },
  validations() {
    return {
      sent: false,
      form: {
        mail: {
          required: helpers.withMessage("This field cannot be empty", required),
          emails: helpers.withMessage(
            "Value is not a valid email address",
            emails
          ),
        },
      },
    };
  },
  methods: {
    async send() {
      let isFormCorrect = await this.v$.form.$validate();

      if (isFormCorrect) {
        await axios
          .post("../public/newsleter.php", querystring.stringify(this.form))
          .then((res) => {
            if (res.status === 205) {
              // console.log(res.status);
              this.sent = false;
              this.showModal();
              this.modal.title = "Failure";
              this.modal.message =
                'This email "' +
                this.form.mail +
                '" allredy exist in Newslettre list!';
            } else {
              this.sent = true;
              this.showModal();
              this.modal.title = "Success";
              this.modal.message =
                'This email "' +
                this.form.mail +
                '" successfully added in Newsletter list!';
            }
          });
      } else {
        this.showModal();
        this.modal.title = "Failure";
        this.modal.message = "Email its empty or not valid!";
      }
    },
    showModal() {
      this.$refs.refsModal.moodalShow();
    },
    getPlace() {
      var data = document.getElementById('address').textContent.split(' ').join('+')
      window.open('https://maps.google.com/maps?q=' + data)
    }
  },
  mounted() {
    // var loader = document.querySelector('.preloader').style.display = 'none';
    setTimeout(function () {
      $(".preloader").hide();
    }, 300);

    //===== Prealoder

    $(window).on("load", function (event) {
      $(".preloader").delay(300).fadeOut(300);
    });
    var wow = new WOW();
    wow.init();
    //===== Back to top

    // Show or hide the sticky footer button
    $(window).on("scroll", function (event) {
      if ($(this).scrollTop() > 600) {
        $(".back-to-top").fadeIn(1400);
      } else {
        $(".back-to-top").fadeOut(1400);
      }
    });

    //Animate the scroll to yop
    $(".back-to-top").on("click", function (event) {
      event.preventDefault();

      $("html, body").animate(
        {
          scrollTop: 0,
        },
        800
      );
    });
  },
  computed() {

  }
};
</script>

<style lang="css">
@import "../assets/css/LineIcons.css";
/*@import "../assets/css/animate.css";*/
a > #address:hover {
  color: red;
}

.bn {
  background-image: url(../assets/images/banner/banner-image.png);
}

.bn {
  background-image: url(../assets/images/banner/banner-image.webp);
}

.fmaps {
  background-image: url(../assets/images/map/map-bg.jpg);
}

.fmaps {
  background-image: url(../assets/images/map/map-bg.webp);
}

.ts {
  background-image: url(../assets/images/testimonial/testimonial-bg.jpg);
}
</style>
