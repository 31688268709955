<template>
  <component
    :is="layout"
    :title="createdTitle"
    :description="createdDescription"
    :keywords="createdKeyword"
  >
    <router-view></router-view>
  </component>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import EmptyLayout from "@/layouts/EmptyLayout.vue";

import dotenv from 'dotenv';
dotenv.config();


export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || "main") + "-layout";
    },
    createdTitle() {
      document.title = this.$route.meta.title;
    },
    createdDescription() {
      document.querySelector('meta[name="description"]').content =
        this.$route.meta.description;
    },
    createdKeyword() {
      document.querySelector('meta[name="keywords"]').content =
        this.$route.meta.keywords;
    },
  },
  components: {
    MainLayout,
    EmptyLayout,
  },
  mounted() {},
};
</script>

<style>
</style>
