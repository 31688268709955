<template>
    <div class="navigation-bar">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <nav class="navbar navbar-expand-lg">
                        <router-link class="navbar-brand" to="/">
                            <img src="../assets/images/logo.png" alt="Logo"/>
                        </router-link>
                        <button
                                class="navbar-toggler"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                        >
                            <span class="toggler-icon"></span>
                            <span class="toggler-icon"></span>
                            <span class="toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul id="nav" class="navbar-nav ml-auto">
                                <li
                                        :class="menuItem.class"
                                        v-for="menuItem in menuItems"
                                        :key="menuItem.to"
                                >

                                    <router-link class="page-scroll" :to="menuItem.to">{{ menuItem.title }}
                                    </router-link>

                                </li>
                            </ul>
                            <!-- navbar nav -->
                        </div>
                        <div class="navbar-btn ml-20 d-none d-sm-block">
                            <a class="main-btn" href="tel:+380985555555"
                            ><i class="lni-phone"></i> +38 098 555 55 55</a
                            >
                        </div>
                    </nav>
                    <!-- navbar -->
                </div>
            </div>
            <!-- row -->
        </div>
        <!-- container -->
    </div>
    <!-- navigation bar -->
</template>

<script>
    import $ from "../assets/js/vendor/jquery-1.12.4.min.js";

    export default {
        name: "Nav",
        data: () => ({
            active: '',
            menuItems: [
                {
                    title: "Home",
                    to: "/#home",
                    class: "nav-item"
                },
                {
                    title: "About",
                    to: "/#about",
                    class: "nav-item"
                },
                {
                    title: "Services",
                    to: "/#service",
                    class: "nav-item"
                },
                {
                    title: "Projects",
                    to: "/#project",
                    class: "nav-item"
                },
                {
                    title: "Team",
                    to: "/#team",
                    class: "nav-item"
                },
                {
                    title: "Contact",
                    to: "/#contact",
                    class: "nav-item"
                },
            ],
        }),
        methods: {},
        mounted() {

            //===== Mobile Menu
            var navbarToggler = $(".navbar-toggler"),
                navbar = $(".navbar-nav a");

            navbarToggler.on("click", function () {
                $(this).toggleClass("active");
                $(".navbar-collapse").toggleClass("show");
            });

            navbar.on("click", function () {
                navbarToggler.removeClass("active");
            });

            //===== close navbar-collapse when a  clicked

            navbar.on("click", function () {
                $(".navbar-collapse").removeClass("show");
            });

            //===== Sticky

            $(window).on("scroll", function (event) {
                var scroll = $(window).scrollTop();
                if (scroll < 10) {
                    $(".navigation-bar").removeClass("sticky");
                } else {
                    $(".navigation-bar").addClass("sticky");
                }
            });

        },
        computed() {
            const url = new URL(location.href);
            let anchors = url.hash;
            //===== Section Menu Active
            var scrollLink = $(".page-scroll");
            console.log('computed');
            if (anchors.includes('#')) {
                console.log('true');
                $(window).scroll(function () {
                    var scrollbarLocation = $(this).scrollTop();
                    scrollLink.each(function () {
                        var sectionOffset = $(this.hash).offset().top - 90;
                        if (sectionOffset <= scrollbarLocation) {
                            $(this).parent().addClass("active");
                            $(this).parent().siblings().removeClass("active");
                        }
                    });
                });
            }
        }
    };
</script>

<style scoped>
    .navbar-nav {
        align-items: flex-start;
    }

    .navbar .navbar-nav .nav-item a {
        color: #646464;
        font-family: hind, sans-serif;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 700;
        text-decoration: none;
        -webkit-transition: all 0.4s ease-out 0s;
        -moz-transition: all 0.4s ease-out 0s;
        -ms-transition: all 0.4s ease-out 0s;
        -o-transition: all 0.4s ease-out 0s;
        transition: all 0.4s ease-out 0s;
    }

    .navigation-bar {
        position: absolute;
        top: 0;
        left: 0;
        /* width: 100%; */
        z-index: 99;
        padding: 5px 0;
        box-shadow: 0 5px 21px 0 rgb(254 119 101 / 20%);
        -webkit-transition: all 0.4s ease-out 0s;
        -moz-transition: all 0.4s ease-out 0s;
        -ms-transition: all 0.4s ease-out 0s;
        -o-transition: all 0.4s ease-out 0s;
        transition: all 0.4s ease-out 0s;
    }

    .navigation-bar.sticky {
        position: fixed;
        background-color: #fff;
        -webkit-box-shadow: 0px 5px 21px 0px rgba(254, 119, 101, 0.2);
        -moz-box-shadow: 0px 5px 21px 0px rgba(254, 119, 101, 0.2);
        box-shadow: 0px 5px 21px 0px rgba(80, 68, 66, 0.2);
        /* z-index: 99; */
        padding: 5px 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .navigation-bar.sticky .navbar-btn {
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }

    @media (max-width: 767px) {
        .navigation-bar.sticky .navbar-btn {
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }

    .navbar {
        padding: 0;
        position: relative;
    }

    .navbar .navbar-brand img {
        max-width: 112px;
    }

    .navbar .navbar-toggler .toggler-icon {
        width: 30px;
        height: 2px;
        display: block;
        background-color: #646464;
        margin: 5px 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .navbar .navbar-collapse {
            position: absolute;
            top: 128%;
            width: 100%;
            left: 0;
            background-color: #fff;
            padding: 5px 15px;
            z-index: 9;
            -webkit-box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
            -moz-box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
            box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
        }
    }

    @media (max-width: 767px) {
        .navbar .navbar-collapse {
            position: absolute;
            top: 128%;
            width: 100%;
            left: 0;
            background-color: #fff;
            padding: 5px 15px;
            z-index: 9;
            -webkit-box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
            -moz-box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
            box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
            transition: all 0.4s ease-out 0s;
        }
    }

    .navbar .navbar-nav .nav-item {
        margin-right: 30px;
    }

    @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .navbar .navbar-nav .nav-item {
            margin-right: 25px;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .navbar .navbar-nav .nav-item {
            margin: 5px 0;
        }
    }

    @media (max-width: 767px) {
        .navbar .navbar-nav .nav-item {
            margin: 5px 0;
        }
    }

    .navbar .navbar-nav .nav-item a {
        color: #646464;
        font-family: "Hind", sans-serif;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 700;
        -webkit-transition: all 0.4s ease-out 0s;
        -moz-transition: all 0.4s ease-out 0s;
        -ms-transition: all 0.4s ease-out 0s;
        -o-transition: all 0.4s ease-out 0s;
        transition: all 0.4s ease-out 0s;
    }

    @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .navbar .navbar-nav .nav-item a {
            font-size: 13px;
        }
    }

    .navbar .navbar-nav .nav-item.active a,
    .navbar .navbar-nav .nav-item:hover a {
        color: #eb3656;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .navbar .navbar-btn {
            position: absolute;
            right: 70px;
            top: 0;
        }
    }

    @media (max-width: 767px) {
        .navbar .navbar-btn {
            position: absolute;
            right: 70px;
            top: 0;
        }
    }

    .navbar-collapse {
        justify-content: end;
    }
</style>
