<template>
    <div class="empty-layout">

        <Preloader />
        <Nav />
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- <h1 class="text-center">EmptyLayout</h1> -->
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="copyright text-center">
                            <p>Copyright</p>
                        </div>
                        <!-- copyright -->
                    </div>
                </div>
                <!-- row -->
            </div>
            <!-- container -->
        </div>
    </div>
</template>
<script>

    import Preloader from "@/components/Preloader";
    import Nav from "@/components/Nav";

    export default {
        name: "EmptyLayout",
        components: {
            Preloader,Nav
        },
        methods: {
            showMblMenu() {
                // $(".navbar-collapse").toggleClass("show");
            }
        },
        computed() {

        },
        mounted() {

        }
    };

</script>
<style lang="css">

    .empty-layout {
        background-color: #e9ecef;
        height: 100%;
    }

    .navbar-nav {
        align-items: flex-start;
    }

    .navbar .navbar-nav .nav-item a {
        color: #646464;
        font-family: hind, sans-serif;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 700;
        text-decoration: none;
        -webkit-transition: all 0.4s ease-out 0s;
        -moz-transition: all 0.4s ease-out 0s;
        -ms-transition: all 0.4s ease-out 0s;
        -o-transition: all 0.4s ease-out 0s;
        transition: all 0.4s ease-out 0s;
    }

    .navigation-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99;
        padding: 5px 0;
        -webkit-transition: all 0.4s ease-out 0s;
        -moz-transition: all 0.4s ease-out 0s;
        -ms-transition: all 0.4s ease-out 0s;
        -o-transition: all 0.4s ease-out 0s;
        transition: all 0.4s ease-out 0s;
    }

    .navigation-bar.sticky {
        position: fixed;
        background-color: #fff;
        -webkit-box-shadow: 0px 5px 21px 0px rgba(254, 119, 101, 0.2);
        -moz-box-shadow: 0px 5px 21px 0px rgba(254, 119, 101, 0.2);
        box-shadow: 0px 5px 21px 0px rgba(254, 119, 101, 0.2);
        z-index: 99;
        padding: 10px 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .navigation-bar.sticky .navbar-btn {
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }

    @media (max-width: 767px) {
        .navigation-bar.sticky .navbar-btn {
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }

    .navbar {
        padding: 0;
        position: relative;
    }

    .navbar .navbar-brand img {
        max-width: 112px;
    }

    .navbar .navbar-toggler .toggler-icon {
        width: 30px;
        height: 2px;
        display: block;
        background-color: #646464;
        margin: 5px 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .navbar .navbar-collapse {
            position: absolute;
            top: 128%;
            width: 100%;
            left: 0;
            background-color: #fff;
            padding: 5px 15px;
            z-index: 9;
            -webkit-box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
            -moz-box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
            box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
        }
    }

    @media (max-width: 767px) {
        .navbar .navbar-collapse {
            position: absolute;
            top: 128%;
            width: 100%;
            left: 0;
            background-color: #fff;
            padding: 5px 15px;
            z-index: 9;
            -webkit-box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
            -moz-box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
            box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
            transition: all 0.4s ease-out 0s;
        }
    }

    .navbar .navbar-nav .nav-item {
        margin-right: 30px;
    }

    @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .navbar .navbar-nav .nav-item {
            margin-right: 25px;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .navbar .navbar-nav .nav-item {
            margin: 5px 0;
        }
    }

    @media (max-width: 767px) {
        .navbar .navbar-nav .nav-item {
            margin: 5px 0;
        }
    }

    .navbar .navbar-nav .nav-item a {
        color: #646464;
        font-family: "Hind", sans-serif;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 700;
        -webkit-transition: all 0.4s ease-out 0s;
        -moz-transition: all 0.4s ease-out 0s;
        -ms-transition: all 0.4s ease-out 0s;
        -o-transition: all 0.4s ease-out 0s;
        transition: all 0.4s ease-out 0s;
    }

    @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .navbar .navbar-nav .nav-item a {
            font-size: 13px;
        }
    }

    .navbar .navbar-nav .nav-item.active a,
    .navbar .navbar-nav .nav-item:hover a {
        color: #eb3656;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .navbar .navbar-btn {
            position: absolute;
            right: 70px;
            top: 0;
        }
    }

    @media (max-width: 767px) {
        .navbar .navbar-btn {
            position: absolute;
            right: 70px;
            top: 0;
        }
    }

    .navbar-collapse {
        justify-content: center;
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
    .fadeIn-enter-active,
    .fadeIn-leave-active {
        transition: opacity 0.8s ease;
    }
    .fadeIn-enter-from,
    .fadeIn-leave-to {
        opacity: 0;
    }
</style>


