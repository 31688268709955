<template>
  <div id="modal">
    <transition name="fadeIn">
      <div class="modal" tabindex="-1" v-if="modalVisibility">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-uppercase">{{ title + "!" }}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="moodalHide()"
              ></button>
            </div>
            <div class="modal-body" v-if="title === 'Failure'">
              <p class="text-danger">{{ message }}</p>
            </div>
            <div class="modal-body" v-else>
              <p class="text-success">{{ message }}</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="main-btn"
                data-bs-dismiss="modal"
                @click="moodalHide()"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
const body = document.querySelector("body");

export default {
  name: "Modal",
  props: { title: String, message: String },
  data() {
    return {
      modalVisibility: false,
    };
  },
  methods: {
    moodalShow() {
      this.modalVisibility = true;
      body.style.overflow = "hidden";
    },
    moodalHide() {
      this.modalVisibility = false;
      body.style.overflow = "auto";
      // let invalides = document.querySelectorAll(".form-control");
      // invalides.forEach((element) => {
      //   element.classList.remove("is-invalid");
      // });
    },
  },
  mounted() {
    let validClass = document.querySelectorAll(".is-valid");
    validClass.forEach((valid) => {
      valid.classList.remove("is-valid");
    });
  },
};
</script>

<style lang="css">
.modal {
  top: 10%;
  display: block;
  background: rgb(125, 137, 137);
  background: linear-gradient(
    0deg,
    rgba(125, 137, 137, 0.2416049228625351) 51%,
    rgba(135, 133, 128, 1) 100%
  );
}
.modal-content {
  -webkit-box-shadow: 0px 7px 13px -2px rgba(34, 60, 80, 0.12);
  -moz-box-shadow: 0px 7px 13px -2px rgba(34, 60, 80, 0.12);
  box-shadow: 0px 7px 13px -2px rgba(34, 60, 80, 0.12);
}
.modal-open {
  overflow: hidden;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: opacity 0.8s ease;
}
.fadeIn-enter-from,
.fadeIn-leave-to {
  opacity: 0;
}
</style>