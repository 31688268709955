<template>
            <!--====== PRELOADER PART START ======-->

      <div class="preloader fadeIn">
        <div class="loader_34">
          <div class="ytp-spinner">
            <div class="ytp-spinner-container">
              <div class="ytp-spinner-rotator">
                <div class="ytp-spinner-left">
                  <div class="ytp-spinner-circle"></div>
                </div>
                <div class="ytp-spinner-right">
                  <div class="ytp-spinner-circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--====== PRELOADER ENDS START ======-->
</template>

<script>
  import $ from "../assets/js/vendor/jquery-1.12.4.min.js";
export default {
      name:"Preloader",
  mounted(){
    setTimeout(function () {
      $(".preloader").hide();
    }, 500);

  },
}
</script>
