import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css"
import "./assets/css/default.css";
import "./assets/css/style.css";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "./assets/js/vendor/jquery-1.12.4.min"
import "bootstrap/dist/js/bootstrap"


createApp(App).use(router).mount("#app");
