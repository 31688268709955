import {createRouter, createWebHistory} from 'vue-router'

const routes = [{
    path: '/',
    name: 'Home',
    meta: {
        layout: 'main',
        title: 'Home page | Interior Design Partner',
        description: 'Lorem1',
        keywords: 'key1'
    },
    component: () => import('../views/Home.vue')
},
    {
        path: '/support',
        name: 'Support page',
        meta: {
            layout: 'empty',
            title: 'Support page | Interior Design Partner',
            description: 'Lorem2',
            keywords: 'key2'
        },

        component: () => import('../views/Support.vue')
    },
    {
        path: '/products',
        name: 'Products page',
        meta: {
            layout: 'empty',
            title: 'Products page | Interior Design Partner',
            description: 'Lorem3',
            keywords: 'key3'
        },

        component: () => import('../views/Products.vue')
    },
    {
        path: '/address',
        name: 'Address page',
        meta: {
            layout: 'empty',
            title: 'Address page | Interior Design Partner',
            description: 'Lorem4',
            keywords: 'key4'
        },

        component: () => import('../views/Address.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,

    scrollBehavior(to, from, savedPosition) {

        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {

            return {
                el: to.hash,
            };
        }
        return { x: 0, y: 0 };
    }
})

export default router
